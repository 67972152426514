import React, { Fragment } from "react"

function BackEndSVG() {
  return (
    <Fragment>
      <svg
        className="back-endSvg"
        viewBox="0 0 512 512"
        height="120"
        fill="none"
        stroke="#25F4F4"
        stroke-width="10"
      >
        <path
          d="M504.419,178.331l-28.064-6.991c-4.488-19.012-11.951-37.017-22.248-53.675l14.901-24.79
                c2.365-3.935,1.748-8.975-1.499-12.221L431.41,44.555c-3.247-3.246-8.287-3.863-12.221-1.499l-24.79,14.901
                c-16.658-10.297-34.663-17.761-53.675-22.248l-6.991-28.064c-1.109-4.455-5.11-7.581-9.701-7.581h-51.044
                c-4.591,0-8.592,3.126-9.701,7.581l-6.991,28.064c-19.012,4.488-37.017,11.951-53.675,22.248l-24.79-14.901
                c-3.932-2.365-8.974-1.748-12.221,1.499l-36.099,36.099c-3.246,3.246-3.864,8.286-1.499,12.221l14.902,24.792
                c-6.206,10.054-11.431,20.698-15.566,31.725c-1.939,5.171,0.681,10.933,5.851,12.872c5.176,1.94,10.933-0.682,12.872-5.851
                c4.344-11.584,10.041-22.696,16.931-33.03c2.146-3.217,2.242-7.383,0.25-10.697l-14.076-23.417l25.052-25.052l23.417,14.077
                c3.312,1.992,7.478,1.894,10.697-0.25c17.732-11.824,37.285-19.928,58.113-24.087c3.793-0.758,6.809-3.635,7.743-7.388
                l6.604-26.511h35.419l6.604,26.511c0.935,3.753,3.95,6.63,7.743,7.388c20.828,4.159,40.381,12.263,58.113,24.087
                c3.218,2.145,7.384,2.242,10.697,0.25l23.417-14.077l25.052,25.052l-14.077,23.417c-1.992,3.314-1.896,7.48,0.25,10.697
                c11.824,17.732,19.928,37.285,24.087,58.113c0.758,3.793,3.635,6.809,7.388,7.743l26.511,6.604v35.419l-26.511,6.604
                c-3.753,0.935-6.63,3.95-7.388,7.743c-4.159,20.828-12.263,40.381-24.087,58.113c-2.145,3.217-2.242,7.383-0.25,10.697
                l14.077,23.417l-25.052,25.052l-23.417-14.077c-3.313-1.993-7.479-1.896-10.697,0.25c-12.681,8.456-26.35,15.014-40.766,19.593
                v-36.262c47.708-19.836,79.297-66.706,79.297-118.84c0-57.388-37.76-106.118-89.737-122.667c-0.318-0.119-0.642-0.223-0.971-0.309
                c-12.014-3.72-24.774-5.726-37.995-5.726c-56.203,0-105.502,36.29-122.504,89.168H41.99C18.836,174.02,0,192.856,0,216.009
                v253.937c0,23.153,18.836,41.99,41.99,41.99h263.935c23.153,0,41.99-18.836,41.99-41.99v-80.421
                c16.392-4.586,31.977-11.413,46.485-20.376l24.79,14.901c3.935,2.367,8.975,1.749,12.221-1.499l36.099-36.099
                c3.246-3.246,3.864-8.286,1.499-12.221l-14.901-24.79c10.297-16.658,17.761-34.663,22.248-53.675l28.064-6.991
                c4.455-1.109,7.581-5.11,7.581-9.701v-51.044C512,183.44,508.874,179.439,504.419,178.331z M298.51,104.846
                c1.336,0.001,2.665,0.034,3.99,0.082l-2.043,1.032c-8.661,4.375-14.159,12.769-14.708,22.457s3.968,18.649,12.081,23.974
                c1.939,1.272,3.097,3.417,3.097,5.737v15.892h-36.374c3.714-5.035,5.435-11.236,4.77-17.654c-0.852-8.218-5.443-15.3-12.598-19.43
                c-1.35-0.779-2.305-2.102-2.621-3.627l-3.588-17.328C265.091,108.817,281.423,104.846,298.51,104.846z M232.421,127.211
                l2.102,10.154c1.473,7.104,5.921,13.261,12.204,16.888c2.104,1.215,2.597,3.136,2.704,4.173s0.018,3.019-1.794,4.639
                l-12.246,10.956h-38.17C204.561,155.153,216.882,139.094,232.421,127.211z M327.919,469.946c0,12.128-9.867,21.995-21.995,21.995
                H41.99c-12.128,0-21.995-9.867-21.995-21.995V265.997h307.924V469.946z M327.919,246.002H19.995v-29.993
                c0-12.127,9.867-21.995,21.995-21.995h263.935c12.128,0,21.995,9.867,21.995,21.995V246.002z M320.92,176.798v-18.67
                c0-9.08-4.532-17.475-12.123-22.454c-2.987-1.961-3.154-4.938-3.087-6.126s0.568-4.128,3.759-5.74l25.251-12.753
                c42.192,14.95,72.496,55.248,72.496,102.499c0,22.023-6.684,42.928-18.392,60.431l-3.536-9.947
                c-5.751-16.175-20.488-27.4-37.374-28.865v-19.163C347.914,198.139,336.688,182.85,320.92,176.798z M347.914,255.305
                c8.421,1.362,15.614,7.219,18.534,15.431l7.493,21.076c-7.624,7.365-16.374,13.646-26.028,18.574V255.305z"
        />

        <path
          d="M294.927,210.011H176.051c-5.521,0-9.997,4.477-9.997,9.997s4.476,9.997,9.997,9.997h118.875
                c5.521,0,9.998-4.477,9.998-9.997S300.447,210.011,294.927,210.011z"
        />

        <path
          d="M95.026,212.94c-1.86-1.86-4.439-2.929-7.078-2.929c-2.629,0-5.209,1.069-7.068,2.929
                c-1.859,1.859-2.929,4.439-2.929,7.068s1.07,5.209,2.929,7.067c1.87,1.861,4.439,2.93,7.068,2.93c2.639,0,5.209-1.07,7.078-2.93
                c1.86-1.859,2.919-4.429,2.919-7.067C97.946,217.379,96.886,214.8,95.026,212.94z"
        />

        <path
          d="M57.005,212.94c-1.859-1.86-4.438-2.929-7.067-2.929c-2.63,0-5.209,1.069-7.068,2.929
                c-1.86,1.861-2.929,4.439-2.929,7.068s1.069,5.209,2.929,7.067c1.859,1.861,4.438,2.93,7.068,2.93c2.629,0,5.209-1.07,7.067-2.93
                c1.859-1.859,2.93-4.438,2.93-7.067S58.865,214.8,57.005,212.94z"
        />

        <path
          d="M133.036,212.94c-1.859-1.86-4.438-2.929-7.067-2.929s-5.209,1.069-7.068,2.929s-2.929,4.439-2.929,7.068
                s1.069,5.209,2.929,7.067c1.86,1.861,4.439,2.93,7.068,2.93s5.209-1.07,7.067-2.93c1.859-1.859,2.93-4.438,2.93-7.067
                S134.897,214.8,133.036,212.94z"
        />

        <path
          d="M257.614,309.986h-67.661c-5.521,0-9.997,4.477-9.997,9.997s4.477,9.998,9.997,9.998h67.661
                c5.521,0,9.998-4.477,9.998-9.998S263.135,309.986,257.614,309.986z"
        />

        <path
          d="M307.433,408.892c-1.861-1.86-4.428-2.929-7.068-2.929c-2.629,0-5.209,1.069-7.067,2.929
                c-1.86,1.86-2.93,4.439-2.93,7.068c0,2.629,1.07,5.209,2.93,7.067c1.859,1.87,4.438,2.93,7.067,2.93
                c2.629,0,5.209-1.061,7.068-2.93c1.859-1.859,2.929-4.438,2.929-7.067C310.362,413.33,309.293,410.751,307.433,408.892z"
        />

        <path
          d="M260.602,405.965h-70.649c-5.521,0-9.997,4.477-9.997,9.998s4.477,9.997,9.997,9.997h70.649
                c5.521,0,9.997-4.477,9.997-9.997S266.123,405.965,260.602,405.965z"
        />

        <path
          d="M300.365,357.973H189.953c-5.521,0-9.997,4.477-9.997,9.997s4.477,9.998,9.997,9.998h110.413
                c5.521,0,9.998-4.477,9.998-9.998S305.887,357.973,300.365,357.973z"
        />

        <path
          d="M145.918,309.986H49.939c-5.521,0-9.997,4.477-9.997,9.997v95.979c0,5.521,4.477,9.998,9.997,9.998h95.979
                c5.521,0,9.998-4.477,9.998-9.998v-95.979C155.915,314.463,151.439,309.986,145.918,309.986z M135.92,405.965H59.936v-75.984
                h75.984V405.965z"
        />

        <path
          d="M461.885,383.635c-5.521,0-9.998,4.477-9.998,9.998v58.319h-43.563c1.597-3.659,0.907-8.075-2.086-11.068
                c-3.905-3.903-10.233-3.903-14.14,0l-15.264,15.264c-3.904,3.904-3.904,10.234,0,14.14l15.264,15.265
                c1.953,1.952,4.511,2.928,7.069,2.928c2.558,0,5.117-0.977,7.069-2.928c3.732-3.732,3.887-9.676,0.483-13.604h55.165
                c5.521,0,9.997-4.477,9.997-9.997v-68.318C471.883,388.112,467.406,383.635,461.885,383.635z"
        />

        <path
          d="M126.216,42.981l-15.265-15.264c-3.905-3.903-10.235-3.903-14.14,0c-3.349,3.35-3.816,8.479-1.42,12.336H49.988
                c-5.521,0-9.998,4.477-9.998,9.998V109.4c0,5.522,4.477,9.999,9.998,9.999s9.997-4.477,9.997-9.997V60.048h35.407
                c-2.395,3.857-1.929,8.986,1.42,12.336c1.953,1.952,4.511,2.928,7.069,2.928s5.117-0.977,7.069-2.928l15.265-15.264
                c1.875-1.875,2.928-4.418,2.928-7.069C129.144,47.399,128.09,44.856,126.216,42.981z"
        />

        <path
          d="M57.055,139.239c-1.859-1.86-4.438-2.929-7.067-2.929s-5.209,1.069-7.068,2.929c-1.86,1.859-2.929,4.438-2.929,7.078
                c0,2.629,1.069,5.199,2.929,7.067c1.859,1.86,4.439,2.92,7.068,2.92s5.209-1.061,7.067-2.92c1.859-1.869,2.93-4.438,2.93-7.067
                C59.985,143.677,58.915,141.109,57.055,139.239z"
        />
      </svg>
    </Fragment>
  )
}

export { BackEndSVG }
