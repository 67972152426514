import React, { Fragment, useEffect } from "react"
import anime from "animejs"

function CKsvg() {
  useEffect(() => {
    anime({
      targets:
        ".back-endSvg path, #codebyck path, .front-endSvg path, .designSvg path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInBack",
      duration: 2500,
      autoplay: true,
      delay: function (el, i) {
        return i * 150
      },
      direction: "normal",
      loop: false,
    })
  })

  return (
    <Fragment>
      <svg
        id="codebyck"
        viewBox="0 0 1224 792"
        fill="none"
        stroke="#25F4F4"
        stroke-width="5"
      >
        <path
          d="M207.6,331.6c0-7.332,1.266-14.266,3.8-20.8
		c2.531-6.531,6.2-12.266,11-17.2c4.8-4.931,10.6-8.866,17.4-11.8c6.8-2.931,14.466-4.4,23-4.4c11.466,0,21.231,2.469,29.3,7.4
		c8.065,4.935,14.1,11.4,18.1,19.4l-26.2,8c-2.269-3.866-5.269-6.832-9-8.9c-3.734-2.065-7.869-3.1-12.4-3.1
		c-3.869,0-7.469,0.769-10.8,2.3c-3.334,1.534-6.234,3.669-8.7,6.4c-2.469,2.734-4.4,6.035-5.8,9.9c-1.4,3.869-2.1,8.135-2.1,12.8
		c0,4.668,0.731,8.934,2.2,12.8c1.466,3.869,3.431,7.2,5.9,10c2.466,2.8,5.366,4.969,8.7,6.5c3.331,1.535,6.866,2.3,10.6,2.3
		c4.8,0,9.231-1.2,13.3-3.6c4.066-2.4,6.9-5.332,8.5-8.8l26.2,8c-3.6,8-9.535,14.535-17.8,19.6c-8.269,5.069-18.2,7.6-29.8,7.6
		c-8.534,0-16.2-1.469-23-4.4s-12.6-6.9-17.4-11.9c-4.8-5-8.5-10.8-11.1-17.4C208.9,345.7,207.6,338.8,207.6,331.6z"
        />

        <g>
          <path
            d="M360.829,385.619c-8.535,0-16.2-1.469-23-4.4
		c-6.8-2.931-12.569-6.9-17.3-11.9c-4.734-5-8.369-10.766-10.9-17.3c-2.534-6.531-3.8-13.4-3.8-20.6c0-7.331,1.266-14.266,3.8-20.8
		c2.531-6.531,6.166-12.3,10.9-17.3c4.731-5,10.5-8.966,17.3-11.9c6.8-2.931,14.465-4.4,23-4.4c8.531,0,16.166,1.469,22.9,4.4
		c6.731,2.935,12.466,6.9,17.2,11.9c4.731,5,8.365,10.769,10.9,17.3c2.531,6.534,3.8,13.469,3.8,20.8c0,7.2-1.269,14.069-3.8,20.6
		c-2.535,6.535-6.134,12.3-10.8,17.3c-4.668,5-10.4,8.969-17.2,11.9C377.029,384.15,369.36,385.619,360.829,385.619z
		 M333.429,331.418c0,4.669,0.7,8.9,2.1,12.7c1.4,3.8,3.332,7.1,5.8,9.9c2.465,2.8,5.366,4.969,8.7,6.5
		c3.332,1.535,6.932,2.3,10.8,2.3c3.866,0,7.465-0.766,10.8-2.3c3.332-1.531,6.231-3.7,8.7-6.5c2.465-2.8,4.4-6.131,5.8-10
		c1.4-3.866,2.1-8.131,2.1-12.8c0-4.531-0.7-8.731-2.1-12.6c-1.4-3.866-3.334-7.2-5.8-10c-2.469-2.8-5.369-4.966-8.7-6.5
		c-3.334-1.531-6.934-2.3-10.8-2.3c-3.869,0-7.469,0.8-10.8,2.4c-3.334,1.6-6.234,3.8-8.7,6.6c-2.469,2.8-4.4,6.134-5.8,10
		C334.129,322.688,333.429,326.887,333.429,331.418z"
          />
        </g>
        <g>
          <path
            d="M465.829,385.619c-7.2,0-13.869-1.4-20-4.2
		c-6.134-2.8-11.4-6.666-15.8-11.6c-4.4-4.932-7.834-10.7-10.3-17.3c-2.469-6.6-3.7-13.7-3.7-21.3s1.166-14.666,3.5-21.2
		c2.332-6.531,5.566-12.266,9.7-17.2c4.131-4.932,9.065-8.8,14.8-11.6c5.731-2.8,12-4.2,18.8-4.2c7.731,0,14.7,1.869,20.9,5.6
		c6.2,3.734,11.031,8.6,14.5,14.6v-59.6h26.8v115.2c0,2.8,0.466,4.8,1.4,6c0.931,1.2,2.531,1.869,4.8,2v22.8
		c-4.669,0.931-8.469,1.4-11.4,1.4c-4.8,0-8.734-1.2-11.8-3.6c-3.068-2.4-4.8-5.531-5.2-9.4l-0.6-6.6
		c-3.869,6.669-9.1,11.7-15.7,15.1C479.929,383.918,473.029,385.619,465.829,385.619z M472.829,362.819c2.531,0,5.131-0.432,7.8-1.3
		c2.666-0.866,5.131-2.1,7.4-3.7c2.266-1.6,4.3-3.466,6.1-5.6c1.8-2.131,3.166-4.466,4.1-7v-25c-1.069-2.931-2.569-5.631-4.5-8.1
		c-1.935-2.466-4.134-4.6-6.6-6.4c-2.469-1.8-5.069-3.231-7.8-4.3c-2.734-1.065-5.435-1.6-8.1-1.6c-4,0-7.7,0.869-11.1,2.6
		c-3.4,1.734-6.3,4.1-8.7,7.1c-2.4,3-4.269,6.435-5.6,10.3c-1.334,3.869-2,7.869-2,12c0,4.4,0.731,8.469,2.2,12.2
		c1.466,3.734,3.466,7,6,9.8c2.531,2.8,5.566,5,9.1,6.6C464.66,362.019,468.56,362.819,472.829,362.819z"
          />
        </g>
        <g>
          <path
            d="M582.529,386.12c-8.4,0-16-1.435-22.8-4.3
		c-6.8-2.866-12.6-6.766-17.4-11.7c-4.8-4.931-8.5-10.666-11.1-17.2c-2.6-6.531-3.9-13.4-3.9-20.6c0-7.466,1.266-14.5,3.8-21.1
		c2.531-6.6,6.2-12.4,11-17.4c4.8-5,10.631-8.966,17.5-11.9c6.866-2.931,14.566-4.4,23.1-4.4c8.531,0,16.166,1.469,22.9,4.4
		c6.731,2.935,12.466,6.869,17.2,11.8c4.731,4.934,8.331,10.668,10.8,17.2c2.466,6.535,3.7,13.334,3.7,20.4
		c0,1.734-0.034,3.4-0.101,5c-0.068,1.6-0.234,2.934-0.5,4h-81c0.4,4.134,1.4,7.8,3,11c1.6,3.2,3.666,5.934,6.2,8.2
		c2.531,2.269,5.4,4,8.6,5.2c3.2,1.2,6.531,1.8,10,1.8c5.332,0,10.366-1.3,15.1-3.9c4.731-2.6,7.966-6.031,9.7-10.3l23,6.4
		c-3.868,8-10.034,14.569-18.5,19.7C604.36,383.554,594.26,386.12,582.529,386.12z M609.729,322.919
		c-0.669-7.865-3.569-14.166-8.7-18.9c-5.134-4.731-11.369-7.1-18.7-7.1c-3.6,0-6.969,0.635-10.1,1.9
		c-3.134,1.269-5.9,3.034-8.3,5.3c-2.4,2.269-4.369,5-5.9,8.2c-1.534,3.2-2.434,6.734-2.7,10.6H609.729z"
          />
        </g>
        <g>
          <path
            d="M697.748,386.12c-8,0-15.135-1.8-21.4-5.4
		c-6.268-3.6-11.199-8.531-14.799-14.8v18.2h-23.4v-146h26.8v59.6c3.731-6.4,8.632-11.366,14.7-14.9
		c6.065-3.531,13.166-5.3,21.3-5.3c6.8,0,13,1.469,18.601,4.4c5.6,2.935,10.431,6.869,14.5,11.8
		c4.064,4.934,7.199,10.734,9.399,17.4c2.2,6.669,3.3,13.669,3.3,21c0,7.6-1.234,14.669-3.699,21.2
		c-2.469,6.534-5.9,12.234-10.301,17.1c-4.4,4.869-9.6,8.7-15.6,11.5C711.148,384.72,704.68,386.12,697.748,386.12z M690.348,363.32
		c4.266,0,8.201-0.832,11.801-2.5c3.6-1.666,6.666-3.9,9.199-6.7c2.531-2.8,4.5-6.065,5.9-9.8c1.4-3.731,2.1-7.731,2.1-12
		c0-4.266-0.668-8.332-2-12.2c-1.334-3.866-3.234-7.266-5.699-10.2c-2.469-2.931-5.369-5.266-8.7-7c-3.335-1.731-7-2.6-11-2.6
		c-6,0-11.368,1.969-16.101,5.9c-4.734,3.934-8.368,8.769-10.899,14.5v25c0.931,2.668,2.3,5.069,4.101,7.2
		c1.799,2.135,3.83,3.969,6.1,5.5c2.266,1.535,4.73,2.734,7.4,3.6C685.214,362.888,687.814,363.32,690.348,363.32z"
          />
        </g>
        <g>
          <path
            d="M743.451,402.227c2.266,0.666,4.465,1.166,6.6,1.5
		c2.131,0.332,3.932,0.5,5.4,0.5c1.73,0,3.331-0.334,4.8-1c1.466-0.668,2.8-1.834,4-3.5c1.2-1.668,2.399-3.968,3.6-6.899
		c1.2-2.935,2.466-6.6,3.8-11l-41.399-104.8h27.6l28.4,81.6l25.2-81.6h25.199l-44,125.6c-2.534,7.332-6.868,13.332-13,18
		c-6.134,4.666-13.734,7-22.8,7c-2.134,0-4.3-0.168-6.5-0.5c-2.2-0.334-4.5-0.9-6.899-1.699V402.227z"
          />
        </g>
        <g>
          <path
            d="M821.029,331.322c0-7.332,1.266-14.266,3.799-20.8
		c2.531-6.531,6.201-12.266,11-17.2c4.801-4.931,10.601-8.866,17.4-11.8c6.8-2.931,14.466-4.4,23-4.4
		c11.466,0,21.231,2.469,29.3,7.4c8.066,4.935,14.101,11.4,18.101,19.4l-26.2,8c-2.269-3.866-5.269-6.832-9-8.9
		c-3.734-2.065-7.869-3.1-12.4-3.1c-3.868,0-7.469,0.769-10.8,2.3c-3.334,1.534-6.234,3.669-8.7,6.4
		c-2.469,2.734-4.399,6.035-5.8,9.9c-1.399,3.869-2.1,8.135-2.1,12.8c0,4.668,0.731,8.934,2.2,12.8c1.465,3.869,3.431,7.2,5.899,10
		c2.466,2.8,5.366,4.969,8.7,6.5c3.331,1.535,6.865,2.3,10.6,2.3c4.801,0,9.231-1.2,13.301-3.6c4.065-2.4,6.899-5.332,8.5-8.8
		l26.199,8c-3.6,8-9.534,14.535-17.8,19.6c-8.269,5.069-18.2,7.6-29.8,7.6c-8.534,0-16.2-1.469-23-4.4s-12.601-6.9-17.399-11.9
		c-4.801-5-8.5-10.8-11.101-17.4C822.328,345.422,821.029,338.522,821.029,331.322z"
          />
        </g>
        <g>
          <path
            d="M996.345,385.458l-30.399-45.4l-14.4,14.2v31.2
		h-26.8v-146h26.8v88.6l42-47.199h28.6l-39,44.399l41.801,60.2H996.345z"
          />
        </g>
      </svg>
    </Fragment>
  )
}

export default CKsvg
