import React, { Fragment } from "react"

function DesignSVG() {
  return (
    <Fragment>
      <svg
        className="designSvg"
        viewBox="0 0 512 512"
        height="120"
        fill="none"
        stroke="#25F4F4"
        stroke-width="10"
      >
        <path
          d="M256.948,353.997c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h0.095c5.523,0,9.952-4.478,9.952-10
                    C266.995,358.475,262.471,353.997,256.948,353.997z"
        />

        <path
          d="M469.999,40h-12v-30c0-5.523-4.477-10-10-10H368c-5.523,0-10,4.477-10,10v304.001h-47.957V168.658l17.679-48.216
                    c1.027-2.801,0.75-5.914-0.755-8.489l-30.368-51.953H325.5c5.523,0,10-4.477,10-10s-4.477-10-10-10h-40.592L264.423,4.955
                    c-1.808-3.093-5.133-5.018-8.715-4.953c-3.583,0.029-6.875,1.972-8.633,5.094l-60.227,106.999
                    c-1.38,2.452-1.664,5.371-0.781,8.042l15.977,48.356v145.509h-39.771V107c0-1.429-0.306-2.841-0.898-4.142L141.874,60h45.126
                    c5.523,0,10-4.477,10-10s-4.477-10-10-10h-52.667c-0.512,0-1.011,0.051-1.503,0.125L117.24,5.859
                    c-1.624-3.568-5.182-5.858-9.102-5.858s-7.479,2.291-9.102,5.858L83.501,40h-41.5c-23.159,0-42,18.841-42,42v281.997
                    c0,27.57,22.43,50,50,50H202V452h-14c-23.159,0-42,18.841-42,42v8c0,5.522,4.477,10,10,10h199.998c5.523,0,10-4.478,10-10v-8
                    c0-23.159-18.841-42-42-42h-14v-38.004h151.999c27.57,0,50-22.43,50-50V82C511.998,58.841,493.156,40,469.999,40z M378,299.998
                    h21.25c5.523,0,10-4.478,10-10s-4.477-10-10-10H378v-20h8.951c5.523,0,10-4.477,10-10c0-5.523-4.477-10-10-10H378v-20h21.25
                    c5.523,0,10-4.477,10-10s-4.477-10-10-10H378v-20h8.951c5.523,0,10-4.477,10-10s-4.477-10-10-10H378v-20h21.25
                    c5.523,0,10-4.477,10-10c0-5.523-4.477-10-10-10H378v-20h8.951c5.523,0,10-4.477,10-10s-4.477-10-10-10H378V60h21.25
                    c5.523,0,10-4.477,10-10c0-5.523-4.477-10-10-10H378V20h59.999v294.001H378V299.998z M246,47.779v35.207c0,5.523,4.477,10,10,10
                    c5.523,0,10-4.477,10-10V47.285l41.323,70.695l-14.264,38.904h-73.787l-12.829-38.828L246,47.779z M263.667,231.664
                    c-5.523,0-10,4.477-10,10v72.337h-31.623V176.883h67.999v137.119h-16.377v-72.337C273.667,236.141,269.19,231.664,263.667,231.664
                    z M142.274,116.999v197.002H74.002V116.999H142.274z M108.138,34.146L119.447,59H96.829L108.138,34.146z M87.728,79h40.819
                    l8.191,18H79.539L87.728,79z M20.002,82c0-12.131,9.869-22,22-22h32.399L54.9,102.858c-0.592,1.301-0.898,2.713-0.898,4.142
                    v207.002h-34V82z M324,472c11.457,0,20.896,8.803,21.91,20H166.092c1.013-11.197,10.453-20,21.91-20h24H300H324z M222,452v-38.004
                    H290V452H222z M491.998,363.997c0,16.542-13.458,30-30,30H300h-87.999H50.003c-16.542,0-30-13.458-30-30v-29.996H368h79.999h44
                    V363.997z M491.998,314.002h-34V60h12c12.131,0,22,9.869,22,22V314.002z"
        />

        <path
          d="M100.335,188.499c-5.523,0-10,4.477-10,10v73c0,5.522,4.477,10,10,10s10-4.478,10-10v-73
                    C110.335,192.976,105.858,188.499,100.335,188.499z"
        />

        <path
          d="M107.401,152.429c-1.86-1.86-4.43-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
                    s4.44,2.93,7.07,2.93c2.64,0,5.21-1.07,7.07-2.93c1.87-1.86,2.93-4.44,2.93-7.07C110.331,156.859,109.271,154.289,107.401,152.429
                    z"
        />

        <path
          d="M263.071,109.93c-1.86-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07
                    c0,2.63,1.07,5.21,2.93,7.07s4.439,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93s2.93-4.44,2.93-7.07
                    C266.001,114.369,264.931,111.79,263.071,109.93z"
        />
      </svg>
    </Fragment>
  )
}

export { DesignSVG }
